import React, { Component } from 'react';
import axios from 'axios';
import Spinner from './UI/Spinner';

export default class Ranking extends Component {
  state = {
    teamDetail: null,
    loading: false,
  };

  componentDidMount() {
    this.props.teamGuid && this.updateRanking();
  }

  updateRanking = () => {
    this.setState({ loading: true });
    axios(
      `https://vblcb.wisseq.eu/VBLCB_WebService/data/TeamDetailByGuid?teamguid=${this.props.teamGuid}`
    )
      .then(res => res.data)
      .then(res =>
        this.setState({
          teamDetail: res[0],
          loading: false,
        })
      )
      .catch(err => console.log(err));
  };

  render() {
    const { teamDetail, loading } = this.state;
    const numberCol = { width: '35px', textAlign: 'center' };
    const bigNumberCol = { width: '45px', textAlign: 'center' };

    if (loading) {
      return <Spinner />;
    }

    if (teamDetail && teamDetail.poules) {
      const poules = teamDetail.poules.filter(poule => {
        const pouleNaam = poule.naam.toLowerCase();
        if (pouleNaam.includes('beker') || pouleNaam.includes('oefen')) {
          return false;
        }
        return true;
      });
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {poules.map(poule => (
            <div className="rank" key={poule.naam}>
              <div className="head">
                <h3>{poule.naam}</h3>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                >
                  <div style={numberCol}></div>
                  <div style={{ width: '60%' }}>
                    <strong>Team</strong>
                  </div>
                  <div style={numberCol}>
                    <strong>P</strong>
                  </div>
                  <div style={numberCol}>
                    <strong>W</strong>
                  </div>
                  <div style={numberCol}>
                    <strong>L</strong>
                  </div>
                  <div style={numberCol} className="hideMobile">
                    <strong>G</strong>
                  </div>
                  <div style={numberCol} className="hideMobile">
                    <strong>P</strong>
                  </div>
                  <div style={bigNumberCol} className="hideMobile">
                    <strong>T</strong>
                  </div>
                  <div style={bigNumberCol} className="hideMobile">
                    <strong>V</strong>
                  </div>
                </div>
              </div>
              {poule.teams.map(team => (
                <div key={`teamdetails-${team.guid}`}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      padding: '10px 0',
                    }}
                    className={
                      this.props.teamGuid && this.props.teamGuid === team.guid ? 'bold' : ''
                    }
                  >
                    <div style={{ ...numberCol, fontSize: '1.4em', marginRight: '5px' }}>
                      {team.rangNr}
                    </div>
                    <div style={{ width: '60%' }}>{team.naam}</div>
                    <div style={numberCol}>{team.wedAant}</div>
                    <div style={numberCol}>{team.wedWinst}</div>
                    <div style={numberCol}>{team.wedVerloren}</div>
                    <div className="hideMobile" style={numberCol}>
                      {team.wedGelijk}
                    </div>
                    <div className="hideMobile" style={numberCol}>
                      {team.wedPunt}
                    </div>
                    <div className="hideMobile" style={bigNumberCol}>
                      {team.ptTegen}
                    </div>
                    <div className="hideMobile" style={bigNumberCol}>
                      {team.ptVoor}
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return null;
  }
}
