import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Axios from 'axios';

import Layout from '../layout/defaultLayout';
import Ranking from '../components/Ranking';
import Calendar from '../components/Calendar';
import './team.sass';
import Container from '../components/layout/Container';

export const teamQuery = graphql`
  query($guid: String!) {
    contentfulPloeg(guid: { eq: $guid }) {
      naam
      guid
      ploegfoto {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      spelers {
        naam
        foto {
          # fixed(width: 200, height: 200) {
          #   ...GatsbyContentfulFixed_withWebp
          # }
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      coach {
        id
        naam
        foto {
          # fixed(width: 200, height: 200) {
          #   ...GatsbyContentfulFixed_withWebp
          # }
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      assistantCoach {
        id
        naam
        foto {
          # fixed(width: 200, height: 200) {
          #   ...GatsbyContentfulFixed_withWebp
          # }
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

const ResultContainer = styled.div`
  margin: 20px 0;
  hr {
    margin-bottom: 0;
  }
  .rank {
    .head {
      padding: 10px 0;
      position: sticky;
      top: 50px;
      background-color: #fff;
    }
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
`;

const Team = ({ data, pageContext: { teamData, guid } }) => {
  const {
    naam,
    ploegfoto,
    spelers: contentFulPlayers,
    coach,
    assistantCoach,
  } = data.contentfulPloeg;
  const [games, setGames] = useState(null);

  useEffect(() => {
    Axios(`https://vblcb.wisseq.eu/VBLCB_WebService/data/TeamMatchesByGuid?teamguid=${guid}`)
      .then(res => res.data)
      .then(teamGames => setGames(teamGames));
  }, []);

  return (
    <Layout>
      <Container>
        <h1>{naam}</h1>
        {ploegfoto && <Img fadeIn={false} fluid={ploegfoto.fluid} />}
        {(contentFulPlayers || (teamData && teamData.spelers)) && <h2>Spelers</h2>}
        {contentFulPlayers && (
          <div className="players">
            {contentFulPlayers.map(player => (
              <div className="playerCard" key={`spelerDetail-${player.naam}`}>
                {player.foto && <Img fluid={player.foto.fluid} />}
                <p>{player.naam.split('-')[0]}</p>
              </div>
            ))}
          </div>
        )}
        {!contentFulPlayers && (teamData && teamData.spelers) && (
          <ul style={{ marginLeft: 0 }}>
            {teamData.spelers.map(player => (
              <li
                key={player.lidNr}
                style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}
              >
                <span className="playerName">{player.naam}</span>
                <span className="playerBirthDate">{player.sGebDat}</span>
              </li>
            ))}
          </ul>
        )}
        {(coach || assistantCoach) && (
          <>
            <h2>Coaches</h2>
            <div className="players">
              {coach && (
                <div key={coach.id} className={coach.foto ? 'playerCard' : ''}>
                  {coach.foto && <Img fluid={coach.foto.fluid} />}
                  <p>{coach.naam.split('-')[0]}</p>
                </div>
              )}
              {assistantCoach && (
                <div key={assistantCoach.id} className={assistantCoach.foto ? 'playerCard' : ''}>
                  {assistantCoach.foto && <Img fluid={assistantCoach.foto.fluid} />}
                  <p>{assistantCoach.naam.split('-')[0]}</p>
                </div>
              )}
            </div>
          </>
        )}
        <div className="resultContainer">
          <ResultContainer>
            <Ranking teamGuid={guid} />
            <Calendar games={games} title={naam} />
          </ResultContainer>
        </div>
      </Container>
    </Layout>
  );
};

export default Team;
